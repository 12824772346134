<template>
    <div>
        <collapse-panel title="Options & tris" style="margin-top:0.2rem;" :visible="isOptionAndSortPanelVisible">
        <div>
            <b-checkbox switch size="sm" v-model="excludeOpens" v-if="hasOpens">Résultats fédéraux uniquement</b-checkbox>
            <b-checkbox switch size="sm" v-model="with_ranks">Avec classements intermédiaires</b-checkbox>
            <b-checkbox switch size="sm" v-model="with_subtotals">Avec sous-totaux</b-checkbox>
        </div>
        <div>
            Tri:
            <b-radio-group v-model="sortType" size="sm">
                <b-form-radio value="execution">Ordre de passage</b-form-radio>
                <b-form-radio value="rank">Classement</b-form-radio>
                <b-form-radio value="alphabetical">Alphabétique</b-form-radio>
            </b-radio-group>
        </div>
        <div v-if="sortType=='rank' && event.EVENT.hasCompulsories">
            <b-form-select v-model="rankSortType" size="sm" >
                <b-form-select-option value="overall"><b>Total (overall)</b></b-form-select-option>
                <b-form-select-option-group v-if="event.EVENT.hasCompulsories" label="Figures">
                    <b-form-select-option v-if="event.EVENT.hasCompulsories" value="TotalCompulsory"><b>Total</b></b-form-select-option>
                    <b-form-select-option v-if="event.EVENT.hasCompulsories" value="Compulsory_1">Figure 1</b-form-select-option>
                    <b-form-select-option v-if="event.EVENT.hasCompulsories && numberOfCompulsories > 1" value="Compulsory_2">Figure 2</b-form-select-option>
                    <b-form-select-option v-if="event.EVENT.hasCompulsories && numberOfCompulsories > 2" value="Compulsory_3">Figure 3</b-form-select-option>
                    <b-form-select-option v-if="event.EVENT.hasCompulsories && numberOfCompulsories > 3" value="Compulsory_4">Figure 4</b-form-select-option>
                    <b-form-select-option v-if="event.EVENT.hasCompulsories && numberOfCompulsories > 4" value="Compulsory_5">Figure 5</b-form-select-option>
                </b-form-select-option-group>
                <b-form-select-option-group v-if="event.EVENT.hasRoutine" label="Routine">
                    <b-form-select-option v-if="event.EVENT.hasRoutine" value="Routine"><b>Total</b></b-form-select-option>
                    <b-form-select-option v-if="event.EVENT.hasRoutine" value="RoutineExecution"><i>Exécution</i></b-form-select-option>
                    <b-form-select-option v-if="event.EVENT.hasRoutine" value="RoutineContent"><i>Contenu</i></b-form-select-option>
                </b-form-select-option-group>
                <b-form-select-option-group v-if="event.EVENT.hasBallet" label="Ballet">
                    <b-form-select-option v-if="event.EVENT.hasBallet" value="Ballet"><b>Total</b></b-form-select-option>
                    <b-form-select-option v-if="event.EVENT.hasBallet" value="BalletExecution"><i>Exécution</i></b-form-select-option>
                    <b-form-select-option v-if="event.EVENT.hasBallet" value="BalletChoreo"><i>Chorégraphie</i></b-form-select-option>
                </b-form-select-option-group>
            </b-form-select>
        </div>
        </collapse-panel>
        <div ref="render" class="results-render large-table" style="display:flex">
            <slot name="title"></slot>
            <table style="font-size: 0.8rem;">
                <thead>
                    <tr v-if="event.EVENT.hasCompulsories" class="grid-header">
                        <th rowspan="2" colspan="2">Compétiteur</th>
                        <th :colspan="numberOfCompulsories + colspanOptionsCount">Figures</th>
                        <th v-if="event.EVENT.hasRoutine" :colspan="2 + colspanOptionsCount">Routine</th>
                        <th v-if="event.EVENT.hasBallet" :colspan="2 + colspanOptionsCount">Ballet</th>
                        <th colspan="2">Overall</th>
                    </tr>
                    <tr class="grid-header">
                        <th v-if="!event.EVENT.hasCompulsories" colspan="2">Compétiteur</th>
                        <th v-if="event.EVENT.hasCompulsories">Fig.1</th>
                        <th v-if="event.EVENT.hasCompulsories">Fig.2</th>
                        <th v-if="event.EVENT.hasCompulsories">Fig.3</th>
                        <th v-if="event.EVENT.hasCompulsories && with_subtotals">Total</th>
                        <th v-if="event.EVENT.hasCompulsories && with_ranks == true ">Rg.</th>
                        <th v-if="event.EVENT.hasRoutine">Exec.</th>
                        <th v-if="event.EVENT.hasRoutine">Cont.</th>
                        <th v-if="event.EVENT.hasRoutine && with_subtotals">Total</th>
                        <th v-if="event.EVENT.hasRoutine && with_ranks == true ">Rg.</th>
                        <th v-if="event.EVENT.hasBallet">Choré.</th>
                        <th v-if="event.EVENT.hasBallet">Exec.</th>
                        <th v-if="event.EVENT.hasBallet && with_subtotals">Total</th>
                        <th v-if="event.EVENT.hasBallet && with_ranks == true ">Rg.</th>
                        <th>Total</th>
                        <th>Rg.</th>
                    </tr>
                </thead>
                <!--<div style="max-height:300px; overflow-y:scroll;">-->
                <tbody>
                    <tr v-for="row in rows" :key="row.id" :class="{'even': (row.index % 2 == 0), 'odd': (row.index %2 !=0) }">
                        <td class="row-index">{{ row.index }}</td>
                        <td class="name">{{ row.COMPETITOR.name }}</td>
                        <td class="note" v-for="compulsory in row.compulsories" :key="compulsory.id">{{ arrondir(compulsory.noteValue) }}</td>
                        <td class="subtotal" v-if="event.EVENT.hasCompulsories && with_subtotals">{{ arrondir(row.totalCompulsories.noteValue) }}</td>
                        <td class="subrank" v-if="event.EVENT.hasCompulsories && with_ranks==true">{{ (hasOpens && excludeOpens) ? row.totalCompulsories.federal_rank : row.totalCompulsories.rank}}</td>
                        <td class="note" v-if="event.EVENT.hasRoutine">{{ arrondir(row.routine.execution) }}</td>
                        <td class="note" v-if="event.EVENT.hasRoutine">{{ arrondir(row.routine.content) }}</td>
                        <td class="subtotal" v-if="event.EVENT.hasRoutine && with_subtotals==true">{{ arrondir(row.routine.note) }}</td>
                        <td class="subrank" v-if="event.EVENT.hasRoutine && with_ranks==true">{{ (hasOpens && excludeOpens) ? row.routine.federal_rank : row.routine.rank }}</td>
                        <td class="note" v-if="event.EVENT.hasBallet">{{ arrondir(row.ballet.choreo) }}</td>
                        <td class="note" v-if="event.EVENT.hasBallet">{{ arrondir(row.ballet.execution) }}</td>
                        <td class="subtotal" v-if="event.EVENT.hasBallet && with_subtotals==true">{{ arrondir(row.ballet.note) }}</td>
                        <td class="subrank" v-if="event.EVENT.hasBallet && with_ranks==true">{{ (hasOpens && excludeOpens) ? row.ballet.federal_rank : row.ballet.rank }}</td>
                        <td class="total">{{ arrondir(row.note) }}</td>
                        <td class="rank">{{ (hasOpens && excludeOpens) ? row.federal_rank : row.rank }}</td>
                    </tr>
                </tbody>
                <!--</div>-->
            </table>
        </div>
    </div>
</template>

<script>

import { RST_COMPULSORY_NOTE, RST_ROUTINE_NOTE, RST_BALLET_NOTE } from "@/LocalDB";
import CollapsePanel from '../Panels/CollapsePanel.vue';


export default {
  components: { CollapsePanel },
    data(){
        return {
            columns: [],
            table: [],
            with_ranks: true,
            with_subtotals: true,
            typeDeTri: 'rank',
            sortRankType: 'overall',
            numberOfCompulsories: this.event.level == 'novice' ? 2 : 3,
            allRows: [],
            hasOpens: false,
            excludeOpens: false,
        };
    },
    props: {
            event : { type: Object, required: true, },
            competitor: { type: Object, default: null},
            includeJudgeDetails: {type: Boolean, default: false},
            isOptionAndSortPanelVisible: { type: Boolean, default: true},
        },
    computed:{
        withRanks:{
            get: function()  { return this.with_ranks; },
            set: function(newValue) { this.with_ranks = newValue; this.refresh();}
        },
        sortType :{
            get: function() { return this.typeDeTri; },
            set: function(newValue) { this.typeDeTri = newValue; this.refresh(); }
        },
        rankSortType: {
            get: function() {return this.sortRankType; },
            set: function(newValue) {this.sortRankType = newValue; this.refresh(); }
        },
        colspanOptionsCount(){
            var ret = (this.with_ranks ? 1 : 0)
                    + (this.with_subtotals ? 1 : 0);
            return ret;
        },
        rows(){
            if(!this.hasOpens || !this.excludeOpens)
                return this.allRows;
            return this.allRows.filter(comp => !comp.isOpen);
        }
    },
    methods:{
        refresh(){
            console.log('refresh', this.event);

            var sortCol = "";
            switch(this.typeDeTri){
                case "execution":
                    sortCol = 'effective_order';
                    break;
                case "rank":
                    if(this.sortRankType == 'overall')
                        sortCol = "rank";
                    break;
/*              case "alphabetical":
                    Ce cas est traité à la fin car on ne peut pas effectuer le tri sur une colonne précise pour l'ordre alphabétique.
*/
            }
            var retRows = this.event._EFFECTIVE_COMPETITORS
                        .orderBy(sortCol)
                        .get()
                        .map((p,i) => { 
                            var c = RST_COMPULSORY_NOTE.query()
                                        .where("competition_id", this.event.competition_id)
                                        .where("round_number", this.event.round_number)
                                        .where("level", this.event.level)
                                        .where("category", this.event.category)
                                        .where("event", this.event.event)
                                        .where("competitor_id", p.competitor_id)
                                        .where(c => c.compulsory_number != null)
                                        .where(c => c.judge_id == null)
                                        .orderBy("compulsory_number")
                                        .get();
                            var tc = RST_COMPULSORY_NOTE.query()
                                        .where("competition_id", this.event.competition_id)
                                        .where("round_number", this.event.round_number)
                                        .where("level", this.event.level)
                                        .where("category", this.event.category)
                                        .where("event", this.event.event)
                                        .where("competitor_id", p.competitor_id)
                                        .where(c => c.compulsory_number == null)
                                        .where(c => c.judge_id == null)
                                        .first();
                            var r = RST_ROUTINE_NOTE.query()
                                        .where("competition_id", this.event.competition_id)
                                        .where("round_number", this.event.round_number)
                                        .where("level", this.event.level)
                                        .where("category", this.event.category)
                                        .where("event", this.event.event)
                                        .where("competitor_id", p.competitor_id)
                                        .where(c => c.judge_id == null)
                                        .first();
                            var b = RST_BALLET_NOTE.query()
                                        .where("competition_id", this.event.competition_id)
                                        .where("round_number", this.event.round_number)
                                        .where("level", this.event.level)
                                        .where("category", this.event.category)
                                        .where("event", this.event.event)
                                        .where("competitor_id", p.competitor_id)
                                        .where(c => c.judge_id == null)
                                        .first();
                            p.index = i + 1;
                            p.compulsories = c;
                            p.totalCompulsories = tc;
                            p.routine = r;
                            p.ballet = b;
                            p.competitor_name = p.COMPETITOR.name;
                            p.competitor_shortname = p.COMPETITOR.shortname;
                            p.isOpen = p.RST_COMPETITOR.isOpen;
                            this.hasOpens |= p.isOpen;
                            return p;
                            }
                        );
                        console.log('refresh (data)', this.rows);

            var needSort = false;
            switch(this.typeDeTri){
                case "alphabetical":
                    retRows.sort((a,b) => a.COMPETITOR.name.localeCompare(b.COMPETITOR.name));
                    needSort = true;
                    break;
                case "rank":
                    switch(this.sortRankType){
                        case 'TotalCompulsory':
                            retRows.sort((a,b) => a.totalCompulsories.rank - b.totalCompulsories.rank);
                            needSort = true;
                            break;
                        case "Compulsory_1":
                            retRows.sort((a,b) => a.compulsories[0].rank - b.compulsories[0].rank);
                            needSort = true;
                            break;
                        case "Compulsory_2":
                            retRows.sort((a,b) => a.compulsories[1].rank - b.compulsories[1].rank);
                            needSort = true;
                            break;
                        case "Compulsory_3":
                            retRows.sort((a,b) => a.compulsories[2].rank - b.compulsories[2].rank);
                            needSort = true;
                            break;
                        case "Compulsory_4":
                            retRows.sort((a,b) => a.compulsories[3].rank - b.compulsories[3].rank);
                            needSort = true;
                            break;
                        case "Compulsory_5":
                            retRows.sort((a,b) => a.compulsories[4].rank - b.compulsories[4].rank);
                            needSort = true;
                            break;

                        case "Routine":
                            retRows.sort((a,b) => a.routine.rank - b.routine.rank);
                            needSort = true;
                            break;
                        case "RoutineExecution":
                            retRows.sort((a,b) => a.routine.execution_rank - b.routine.execution_rank);
                            needSort = true;
                            break;
                        case "RoutineContent":
                            retRows.sort((a,b) => a.routine.content_rank - b.routine.content_rank);
                            needSort = true;
                            break;

                        case "Ballet":
                            retRows.sort((a,b) => a.ballet.rank - b.ballet.rank);
                            needSort = true;
                            break;
                        case "BalletExecution":
                            retRows.sort((a,b) => a.ballet.execution_rank - b.ballet.execution_rank);
                            needSort = true;
                            break;
                        case "BalletChoreo":
                            retRows.sort((a,b) => a.ballet.choreo_rank - b.ballet.choreo_rank);
                            needSort = true;
                            break;
                    }
            }

            if(needSort)
                retRows.map((r,i) => { r.index = i + 1; return r});

            this.allRows = retRows;
        }
    },
    mounted(){
        this.typeDeTri = 'rank';
        this.refresh();
    }
}
</script>

<style >
    .results-render{
        display:flex;
        justify-content: center;
    }
    .results-render > table {
        border-collapse: collapse;
        border: solid 2px var(--ffvlOrangePicto);
    }
    .results-render > table  td, .results-render > table th{
        border: solid 1px var(--ffvlOrangePicto);
        text-align: center;
    }
    table.results { margin-top: 10px;}
    .results{ border-collapse: collapse !important; }
    .results > tbody > tr > th { font-size: 0.95rem !important; orientation: landscape; border:darkgrey 1px solid !important;}
    table.results > tbody > tr > td { font-size: 0.75rem !important; orientation: landscape; border:darkgrey 1px solid !important;}
    table.results > thead > tr.grid-header > th { padding-left:3px !important; padding-right:3px !important; background-color:var(--ffvlBleuLogo) !important; }
    .results > tbody > tr > th.total{ background-color: var(--resultTotalHeader);}
    .results > tbody > tr > td.total{ background-color: var(--resultTotal);}
    .results > tbody > tr > th.rank{ background-color: var(--resultRankHeader);}
    .results > tbody > tr > td.rank{ background-color: var(--resultRank);}
    .results > tbody > tr > th.total, .results > tbody > tr > td.total { font-weight: bolder; color: var(--primary);}
    .results > tbody > tr > th.sorted{ background-color: var(--sortedHeader);}
    .results > tbody > tr > td.sorted{ background-color: var(--sortedCell);}

      tr.even td { background-color: beige;}
    tr.odd td{ background-color: #cdcdfd;}

    .trophy {
        background-color: transparent !important;
        border-left: none !important;
        border-right: solid 2px var(--ffvlOrangePicto) !important;
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-index{ padding-left: 7px; padding-right: 7px;}

    .name{ min-width:100px; max-width:300px; text-overflow: ellipsis; padding-left: 2px; text-align: left !important;}

    .note { width: 45px; }
    .subtotal { width: 45px; }
    .subrank { width: 25px; }

    @media screen and (max-width: 767px) and (orientation: portrait) {
        .name{ max-width: 150px; }
        /*.results-render.large-table table {

        }*/
        .results-render.large-table{
            justify-content: flex-start;
            transform-origin: top left;
            width:100%;
            height:100%;
            
            transform: translateX(100%) translateY(0) rotate(90deg) ;
            position:relative;
            overflow:visible;
        }
    /*/    .results-render.large-table {
            position: absolute;
            overflow:auto;
            background-color:green;
            width: 85%;
            height: 800px;
            justify-content:flex-start;
        }
        .results-render.large-table table {
            width:100%;
            background-color: yellow;
            justify-items:center;
            transform: rotate(90deg)  ;
            overflow: visible;
            margin-bottom:40px;
            width: 700px;
         }*/


    }
</style>
